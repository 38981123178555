import React, { useState, useEffect, useCallback } from "react";
import {
  getMailData,
  getUsersMailData,
  sendMail,
} from "../../../../services/services";
import Notiflix from "notiflix";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaUsers, FaUserPlus } from "react-icons/fa6";
import EmailViewModal from "./EmailView";
import { FaArrowRight } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomQuillEditor from "./EmailQuill";

const IconMail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
  </svg>
);

const IconPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <path d="M12 4v16m8-8H4" />
  </svg>
);

const emailValidationSchema = Yup.object({
  subject: Yup.string().required("L'objet est requis"),
  content: Yup.string().required("Le contenu de l'email est requis"),
  toEmails: Yup.string().required("Au moins un destinataire est requis"),
});

const EmailManager = () => {
  const [emails, setEmails] = useState([]);
  const [emailsUsers, setEmailsUsers] = useState([]);
  const [isComposing, setIsComposing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmail, setSelectedEmail] = useState(null);

  const fetchEmails = useCallback(async () => {
    setLoading(true);
    try {
      const { data, response } = await getMailData();
      if (response.status === 200) {
        setEmails(data.data);
      }
    } catch (error) {
      Notiflix.Notify.failure("Erreur lors du chargement des emails");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDataEmails = useCallback(async () => {
    setLoading(true);
    try {
      const { data, response } = await getUsersMailData();

      if (response.status === 200) {
        setEmailsUsers(data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEmails();
    fetchDataEmails();
  }, [fetchEmails]);

  const handleSendEmail = async (values) => {
    try {
      const { data, response } = await sendMail(values);

      if (response.status === 200) {
        console.log(values);
        setIsComposing(false);
        Notiflix.Notify.success("Email envoyé avec succès");
        fetchEmails();
      }
    } catch (error) {
      console.error("Error sending email:", error);
      Notiflix.Notify.failure("Erreur lors de l'envoi de l'email");
    }
  };

  const handleAddAllRecipients = (setFieldValue) => {
    const allEmails = emailsUsers.map((user) => user.EMAIL).join(", ");
    setFieldValue("toEmails", allEmails);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-lg">
        <div className="p-4">
          <button
            onClick={() => setIsComposing(true)}
            className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition-colors"
          >
            <IconPlus />
            Nouveau message
          </button>
        </div>
        <nav className="mt-4">
          <button className="w-full flex items-center gap-3 px-4 py-2 text-gray-700 bg-gray-100">
            <IconMail />
            Boîte de réception
          </button>
        </nav>
      </div>

      {/* Email List */}
      <div className="flex-1 flex flex-col">
        <div className="p-4 bg-white shadow">
          <div className="flex items-center gap-4">
            <input
              type="text"
              placeholder="Rechercher..."
              className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              onClick={fetchEmails}
              className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
            >
              {loading ? "Chargement..." : "↻"}
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-auto">
          {emails.map((email) => (
            <section
              key={email.id}
              onClick={() => setSelectedEmail(email)}
              className=" flex items-center justify-between gap-2 p-4 border-b cursor-pointer hover:bg-gray-50 transition-colors"
            >
              <div className="flex items-center justify-between ">
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <span className="font-medium">{email.from}</span>
                  </div>
                  <p className="text-sm text-gray-600">{email.subject}</p>
                </div>
              </div>

              <FaArrowRight size={20} />
            </section>
          ))}
        </div>
      </div>

      {/* Email Compose Modal */}
      {isComposing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg w-[50%] p-4">
            <div className="flex items-center justify-between border-b pb-2">
              <h3 className="font-semibold text-2xl  ">Nouvelle Email</h3>
              <button
                onClick={() => setIsComposing(false)}
                className="rounded-full p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-colors"
                aria-label="Fermer"
              >
                <MdClose className="h-5 w-5" />
              </button>
            </div>
            <Formik
              initialValues={{
                subject: "",
                content: "",
                toEmails: "",
              }}
              validationSchema={emailValidationSchema}
              onSubmit={handleSendEmail}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <Form>
                  <div className="mb-4 pt-3">
                    <label
                      htmlFor="toEmails"
                      className="block text-sm font-medium"
                    >
                      Destinataires
                    </label>
                    <div className="flex items-center gap-2">
                      <Field
                        as="textarea"
                        id="toEmails"
                        name="toEmails"
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => handleAddAllRecipients(setFieldValue)}
                        className="px-4 py-3 border rounded-lg bg-gray-200 hover:bg-gray-300 transition-bg duration-500"
                        title="Ajouter tous les destinataires"
                      >
                        <FaUserPlus />
                      </button>
                    </div>
                    <ErrorMessage
                      name="toEmails"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="subject"
                      className="block text-sm font-medium"
                    >
                      Objet
                    </label>
                    <Field
                      type="text"
                      id="subject"
                      name="subject"
                      className="w-full px-4 py-2 border rounded-lg"
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="content"
                      className="block flex flex-col items-start text-sm font-medium"
                    >
                      Contenu
                    </label>
                    <CustomQuillEditor
                      value={values.content}
                      onChange={(content) => setFieldValue("content", content)}
                      placeholder="Rédigez votre email ici..."
                    />
                    <ErrorMessage
                      name="content"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  <div className="flex justify-between items-center">
                    <button
                      type="button"
                      onClick={() => setIsComposing(false)}
                      className=" px-5 py-2 border text-red-600 rounded-lg hover:bg-gray-50"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                    >
                      Envoyer
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {selectedEmail && (
        <EmailViewModal
          email={selectedEmail}
          onClose={() => setSelectedEmail(null)}
        />
      )}
    </div>
  );
};

export default EmailManager;
