import React, { useEffect } from "react";
import Containers from "../../components/Containers";
import Users from "./container/Users";

import EmailEditor from "./container/Email/Email";

const UsersPage = () => {
  document.title = "Utilisateurs";
  const tabs = [
    {
      name: "Utilisateurs",
      content: <Users />,
      permission: "STAFF",
    },
    {
      name: "Email",
      content: <EmailEditor />,
      permission: "STAFF",
    },
    // {
    //   name: "Email",
    //   content: <EmailEditor />,
    //   permission: "STAFF",
    // },
  ];
  return <Containers tabs={tabs} title="Utilisateurs" />;
};

export default UsersPage;
