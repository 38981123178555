import axios from "axios";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaGithub,
  FaYoutube,
  FaRedditAlien,
  FaTwitch,
  FaSnapchatGhost,
  FaDiscord,
  FaCheckSquare,
  FaWindowClose,
} from "react-icons/fa";
import { FaXTwitter, FaTiktok, FaLockOpen, FaLock } from "react-icons/fa6";
import React from "react";
import { FcAlarmClock, FcHighPriority, FcOk, FcProcess } from "react-icons/fc";

/**
 * Effectue une requête HTTP en utilisant Axios.
 *
 * @param {string} url - L'URL de la requête.
 * @param {string} [method='GET'] - La méthode HTTP (GET, POST, PUT, DELETE, etc.).
 * @param {object} [data=null] - Les données à envoyer avec la requête (pour les méthodes POST/PUT).
 * @param {object} [headers={}] - Les en-têtes HTTP personnalisés.
 * @param {string} [auth=null] - Le token d'authentification Bearer.
 * @param {object} [otherConfig={}] - Autres configurations pour Axios.
 * @returns {Promise<{ data: any, response: object }>} - Une promesse qui résout un objet contenant les données de la réponse et l'objet de réponse complet.
 * @throws {Error} - Lance une erreur en cas d'échec de la requête.
 */
export async function httpRequest(
  url,
  method = "GET",
  data = null,
  headers = {},
  auth = null,
  otherConfig = {},
) {
  try {
    const axiosConfig = {
      method,
      url,
      withCredentials: true,
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
      ...otherConfig,
      headers: {
        "Content-Type": "application/json",
        ...(auth ? { Authorization: `Bearer ${auth}` } : {}),
        ...headers,
      },
      data: data,
    };

    const response = await axios(axiosConfig);
    return { data: response.data, response };
  } catch (error) {
    // console.log(error);
    throw error;
  }
}

/**
 * Calcule le nombre de jours écoulés entre la date donnée et la date actuelle.
 *
 * @param {string} date - La date au format 'JJ/MM/AAAA'.
 * @returns {number|string} - Le nombre de jours écoulés ou un message d'erreur si la date est invalide.
 */
export function getTimeBetweenDate(date) {
  if (date === undefined) return;
  const givenDate = new Date(
    `${date.slice(6, 10)}/${date.slice(3, 5)}/${date.slice(0, 2)}`,
  );
  const currentDate = new Date();

  if (!givenDate.getTime()) return "Date non valide";
  const differenceInMilliseconds = currentDate - givenDate;
  return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
}

/**
 * Objets représentant les icônes des médias sociaux.
 *
 * @typedef {Object} SocialMediaIcons
 * @property {Object} facebook - Icône Facebook.
 * @property {JSX.Element} facebook.icon - Icône Facebook.
 * @property {Object} x - Icône Twitter (anciennement connu sous le nom de Twitter).
 * @property {JSX.Element} x.icon - Icône Twitter.
 * @property {Object} linkedin - Icône LinkedIn.
 * @property {JSX.Element} linkedin.icon - Icône LinkedIn.
 * @property {Object} instagram - Icône Instagram.
 * @property {JSX.Element} instagram.icon - Icône Instagram.
 * @property {Object} github - Icône GitHub.
 * @property {JSX.Element} github.icon - Icône GitHub.
 * @property {Object} youtube - Icône YouTube.
 * @property {JSX.Element} youtube.icon - Icône YouTube.
 * @property {Object} reddit - Icône Reddit.
 * @property {JSX.Element} reddit.icon - Icône Reddit.
 * @property {Object} twitch - Icône Twitch.
 * @property {JSX.Element} twitch.icon - Icône Twitch.
 * @property {Object} snapchat - Icône Snapchat.
 * @property {JSX.Element} snapchat.icon - Icône Snapchat.
 * @property {Object} discord - Icône Discord.
 * @property {JSX.Element} discord.icon - Icône Discord.
 * @property {Object} tiktok - Icône TikTok.
 * @property {JSX.Element} tiktok.icon - Icône TikTok.
 */
export const socialMediaIcons = {
  facebook: {
    icon: <FaFacebookF />,
  },
  x: {
    icon: <FaXTwitter />,
  },
  linkedin: {
    icon: <FaLinkedinIn />,
  },

  instagram: {
    icon: <FaInstagram />,
  },
  github: {
    icon: <FaGithub />,
  },
  youtube: {
    icon: <FaYoutube />,
  },
  reddit: {
    icon: <FaRedditAlien />,
  },
  twitch: {
    icon: <FaTwitch />,
  },
  snapchat: {
    icon: <FaSnapchatGhost />,
  },
  discord: {
    icon: <FaDiscord />,
  },
  tiktok: {
    icon: <FaTiktok />,
  },
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if it's a French number
  if (cleaned.startsWith("33")) {
    const matched = cleaned.match(/^33(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (matched) {
      return `+33 ${matched[1]} ${matched[2]} ${matched[3]} ${matched[4]} ${matched[5]}`;
    }
  }

  // Format for French numbers without country code
  const matched = cleaned.match(/^0?(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  if (matched) {
    return `0${matched[1]} ${matched[2]} ${matched[3]} ${matched[4]} ${matched[5]}`;
  }

  // If no match, return original input
  return phoneNumber;
};

// Format dates for display
export const showDate = (timestamp) => {
  if (!timestamp) return "";

  try {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) return "";

    // Format: DD/MM/YYYY
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

// Convert display date to ISO format for input fields
export const dateToISO = (timestamp) => {
  if (!timestamp) return "";

  try {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) return "";

    return date.toISOString().split("T")[0];
  } catch (error) {
    console.error("Error converting date to ISO:", error);
    return "";
  }
};

export function formatDate(timeInMs) {
  const date = new Date(timeInMs);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // les mois sont indexés de 0 à 11, d'où le +1
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}

export function formatTime(timeInMs) {
  const date = new Date(timeInMs);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}h ${minutes}m ${seconds}s`;
}

export function saveDateAsUnixTimestamp(date) {
  let dateObj;

  if (date) {
    dateObj = new Date(date);

    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date format");
    }
  } else {
    dateObj = new Date();
  }

  return dateObj.getTime();
}

export function customCompare(a, b) {
  const getFirstAlphabetic = (username) => {
    const matches = username.match(/[a-zA-Z]/);
    return matches ? matches[0].toLowerCase() : "";
  };

  const alphabeticA = getFirstAlphabetic(a.USERNAME);
  const alphabeticB = getFirstAlphabetic(b.USERNAME);

  if (alphabeticA === "" && alphabeticB === "") {
    return 0;
  } else if (alphabeticA === "") {
    return 1;
  } else if (alphabeticB === "") {
    return -1;
  } else {
    return alphabeticA.localeCompare(alphabeticB);
  }
}

export const booleanIcon = {
  true: (
    <span className="center">
      <FaCheckSquare className=" text-green-700" />
    </span>
  ),
  false: (
    <span className="center">
      <FaWindowClose className=" text-red-700" />{" "}
    </span>
  ),
};

export const getValidationIcon = (validated) => {
  return validated ? (
    <FaLockOpen className="text-green-800 text-center mt-1" />
  ) : (
    <FaLock className="text-red-700 text-center mt-1" />
  );
};

export function getTimeSinceDate(timestamp = undefined) {
  if (timestamp === undefined) return;

  if (timestamp > 1e10) {
    timestamp = timestamp / 1000;
  }

  const givenDate = new Date(timestamp * 1000);
  if (isNaN(givenDate.getTime())) return "Timestamp non valide";

  const currentDate = new Date();

  const differenceInMilliseconds = currentDate - givenDate;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24),
  );
  const differenceInMonths = Math.floor(differenceInDays / 30);
  const differenceInYears = Math.floor(differenceInMonths / 12);

  if (differenceInYears > 0) {
    return `il y a ${differenceInYears} années`;
  } else if (differenceInMonths > 0) {
    return `il y a ${differenceInMonths} mois`;
  }
  if (differenceInDays === 0) {
    return "aujourd'hui";
  } else if (differenceInDays === 1) {
    return "hier";
  } else {
    return `il y a ${differenceInDays} jours`;
  }
}

export const getYouTubeEmbedUrl = (url) => {
  const videoId = url.split("v=")[1]?.split("&")[0];
  return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
};

/**
 * Generates an array of contrasting colors in hex format.
 *
 * @param {number} numColors - The number of contrasting colors to generate.
 * @returns {string[]} An array of hex color strings.
 */
export const getContrastingColors = (numColors) => {
  const colors = [];
  const step = 360 / numColors; // Determine the hue step

  for (let i = 0; i < numColors; i++) {
    const hue = (i * step) % 360; // Calculate hue
    const saturation = Math.floor(Math.random() * 41) + 60; // Saturation between 60 and 100
    const lightness = Math.floor(Math.random() * 41) + 30; // Lightness between 30 and 70

    // Convert HSL to hex
    const color = hslToHex(hue, saturation, lightness);
    colors.push(color);
  }

  return colors;
};

/**
 * Converts HSL color values to a hex color string.
 *
 * @param {number} h - The hue component of the HSL color (0-360).
 * @param {number} s - The saturation component of the HSL color (0-100).
 * @param {number} l - The lightness component of the HSL color (0-100).
 * @returns {string} The hex color string representing the HSL color.
 */
export const hslToHex = (h, s, l) => {
  let r, g, b;

  h /= 360;
  s /= 100;
  l /= 100;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return (
    "#" +
    (
      (1 << 24) +
      (Math.round(r * 255) << 16) +
      (Math.round(g * 255) << 8) +
      Math.round(b * 255)
    )
      .toString(16)
      .slice(1)
  );
};

export const StatusBadge = (status) => {
  let color, icon, text;
  switch (status) {
    case "pending":
      color = "bg-yellow-100 text-yellow-800";
      icon = <FcAlarmClock className="w-4 h-4 mr-1" />;
      text = "En attente de traitement";
      break;
    case "in progress":
      color = "bg-blue-100 text-blue-800";
      icon = <FcProcess className="w-4 h-4 mr-1 animate-spin" />;
      text = "En cours de traitement";
      break;
    case "completed":
      color = "bg-green-100 text-green-800";
      icon = <FcOk className="w-4 h-4 mr-1" />;
      text = "Traité";
      break;
    default:
      color = "bg-gray-100 text-gray-800";
      icon = <FcHighPriority className="w-4 h-4 mr-1" />;
      text = "Statut inconnu";
  }

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${color}`}
    >
      {icon}
      {text}
    </span>
  );
};

export const extractEmailContent = (htmlContent) => {
  try {
    // Créer un DOM virtuel
    const dom = new JSDOM(htmlContent);
    const document = dom.window.document;

    // Trouver la div content qui contient le contenu principal
    const contentDivs = document.querySelectorAll(".content");

    // Prendre la deuxième div content (celle qui contient le contenu réel)
    // Index 1 car c'est la div imbriquée qui contient le contenu de l'email
    if (contentDivs.length >= 2) {
      const innerContentDiv = contentDivs[1];
      return innerContentDiv.innerHTML.trim();
    }

    return null;
  } catch (error) {
    console.error("Error extracting content:", error);
    return null;
  }
};

const exportedFunction = {
  getTimeBetweenDate,
  socialMediaIcons,
  showDate,
  formatDate,
  formatTime,
  customCompare,
  booleanIcon,
  saveDateAsUnixTimestamp,
  getTimeSinceDate,
  getYouTubeEmbedUrl,
  getValidationIcon,
  getContrastingColors,
  hslToHex,
  StatusBadge,
  formatPhoneNumber,
  extractEmailContent,
};

export default exportedFunction;
