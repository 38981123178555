import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { getTrackData } from "../../../services/services";
import { clsx } from "clsx";
import { fr } from "react-day-picker/locale";
import { TfiReload } from "react-icons/tfi";
import { VscGraph, VscGraphLine } from "react-icons/vsc";
import { QR_MOCKED_DATA } from "../../../data/Mocked_data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
);

const DataChart = () => {
  const USE_MOCKED_DATA = false;
  const [data, setData] = useState(USE_MOCKED_DATA ? QR_MOCKED_DATA : []);

  const [selectedRange, setSelectedRange] = useState({ from: null, to: null });
  const [filteredData, setFilteredData] = useState([]);
  const [animate, setAnimate] = useState(false);
  const [chartType, setChartType] = useState("Bar");
  const [selectedMonth, setSelectedMonth] = useState(() => new Date());

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  async function getQrTrackData() {
    if (USE_MOCKED_DATA) return;
    try {
      const { response, data } = await getTrackData();

      if (response.status === 200) {
        setData(data.data);
        addDayNoData(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getQrTrackData();
  }, []);

  useEffect(() => {
    if (!data || data.length === 0) return;

    if (selectedRange?.from && selectedRange?.to) {
      const start = new Date(selectedRange.from);
      const end = new Date(selectedRange.to);

      const daysInRange = [];
      for (
        let date = new Date(start);
        date <= end;
        date.setDate(date.getDate() + 1)
      ) {
        daysInRange.push(
          `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date.getFullYear()}`,
        );
      }

      const filledData = daysInRange.map((date) => {
        const existing = data.find((item) => item.DATE === date);
        return existing || { DATE: date, COUNT: 0 };
      });

      setFilteredData(filledData);
    } else {
      updateDataForMonth(selectedMonth);
    }
  }, [data, selectedRange, selectedMonth]);

  const total = filteredData.reduce((sum, item) => sum + item.COUNT, 0);

  const addDayNoData = (data) => {
    if (!data || data.length === 0) {
      setFilteredData([]);
      return;
    }

    const firstValidDate = data.find(
      (item) => item.DATE && parseDate(item.DATE),
    );
    if (!firstValidDate) return;

    const firstDate = parseDate(firstValidDate.DATE);
    const year = firstDate.getFullYear();
    const month = firstDate.getMonth();

    updateDataForMonth(new Date(year, month, 1));
  };

  const chartData = {
    labels: filteredData.map((item) => {
      const date = parseDate(item.DATE);
      return `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}`;
    }),
    datasets: [
      {
        label: "Utilisateur",
        data: filteredData.map((item) => item.COUNT),
        backgroundColor: "rgba(11,116,141,0.76)",
        hoverBackgroundColor: "rgb(1,30,91)",
        tension: 0.6,
        fill: true,
        options: {
          animations: {
            radius: {
              duration: 400,
              easing: "linear",
              loop: (context) => context.active,
            },
          },
        },
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    type: chartType,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        color: "#fff",
        text:
          selectedRange?.from && selectedRange?.to
            ? `Données pour la période du ${selectedRange.from.toLocaleDateString()} au ${selectedRange.to.toLocaleDateString()}`
            : `Données du mois de: ${new Intl.DateTimeFormat("fr-FR", { month: "long" }).format(selectedMonth).toLocaleUpperCase()} ${new Intl.DateTimeFormat("fr-FR", { year: "numeric" }).format(selectedMonth).toLocaleUpperCase()}`,
      },
      colors: {
        enabled: true,
        forceOverride: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          color: "#fff",
          text: "Dates",
        },
        ticks: {
          color: "#fff",
        },
      },
      y: {
        title: { display: true, color: "#fff", text: "Count" },
        beginAtZero: true,
        ticks: {
          color: "#fff",
          precision: 0,
          stepSize: 1,
        },
      },
    },
  };

  const handleRangeChange = (range) => {
    if (!range || (!range.from && !range.to)) {
      setSelectedRange({ from: null, to: null });
    } else {
      setSelectedRange(range);
    }
  };

  const toggleChartType = () => {
    setChartType((prevType) => (prevType === "Line" ? "Bar" : "Line"));
  };

  const handleReset = () => {
    const now = new Date();
    setSelectedRange({ from: null, to: null });
    setSelectedMonth(now);
    updateDataForMonth(now);
  };

  const handleReload = async () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 1500);
    await getQrTrackData();
  };

  const handleMonthChange = (newMonth) => {
    setSelectedMonth(newMonth);
  };

  const updateDataForMonth = (month) => {
    const year = month.getFullYear();
    const selectedMonthIndex = month.getMonth();

    const daysInMonth = new Date(year, selectedMonthIndex + 1, 0).getDate();
    const completeDays = Array.from({ length: daysInMonth }, (_, index) => {
      const day = index + 1;
      return `${day.toString().padStart(2, "0")}/${(selectedMonthIndex + 1)
        .toString()
        .padStart(2, "0")}/${year}`;
    });

    const filledData = completeDays.map((date) => {
      const existing = data.find((item) => item.DATE === date);
      return existing || { DATE: date, COUNT: 0 };
    });

    setFilteredData(filledData);
  };

  return (
    <div className="flex gap-5 p-4 w-full h-[80vh] mx-auto bg-white shadow-lg rounded-lg">
      <div className="flex flex-col justify-between mb-4">
        <div className="flex items-center gap-5">
          <h2 className="text-lg font-bold mb-2 text-center w-full">
            Information sur le tracking
            <br /> du QR code
          </h2>
          <button
            className="p-2 rounded border-2 hover:border-r-gray-500 transition-border duration-500"
            onClick={handleReload}
          >
            <TfiReload className={clsx(animate && "animate-spin")} />
          </button>
        </div>
        <DayPicker
          locale={fr}
          mode="range"
          month={selectedMonth}
          onMonthChange={handleMonthChange}
          selected={selectedRange}
          onSelect={handleRangeChange}
          numberOfMonths={1}
          fromYear={2024}
          toYear={2030}
          captionLayout="dropdown"
        />
        <div className="mt-4 text-center">
          <strong>Nombre de visite Total:</strong> {total}
        </div>
        <div className="flex items-center justify-between">
          <button
            onClick={handleReset}
            className="h-10 mt-4 px-4 py-2 bg-red-700 text-white rounded hover:bg-gray-600"
          >
            Réinitialiser
          </button>

          <button
            onClick={toggleChartType}
            className="h-10 mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 font-bold"
          >
            {chartType === "Line" ? <VscGraph /> : <VscGraphLine />}
          </button>
        </div>
      </div>

      <div
        className={clsx(
          !selectedRange.from && !selectedRange.to && "border-2",
          "w-full h-full bg-gray-700 text-white",
        )}
      >
        {filteredData.length > 0 ? (
          chartType === "Line" ? (
            <Line data={chartData} options={chartOptions} />
          ) : (
            <Bar data={chartData} options={chartOptions} />
          )
        ) : (
          <div className="text-sm text-red-500">
            Aucune donnée disponible pour la période sélectionnée.
          </div>
        )}
      </div>
    </div>
  );
};

export default DataChart;
