import { httpRequest } from "../utils/utils";

const BaseURL = "https://backend.imagigeeks.org/api";

// const BaseURL = "http://localhost:30058/api";

export async function getRoutingPermission() {
  return await httpRequest(`${BaseURL}/permission`, "GET");
}

export async function getRoutingPermissionTabs(data) {
  return await httpRequest(`${BaseURL}/permission/tabs`, "POST", data);
}

export async function isAuthenticated() {
  return await httpRequest(`${BaseURL}/auth`, "GET", null, {});
}

export async function logout() {
  return await httpRequest(`${BaseURL}/session/logout`, "GET", null, {});
}

export async function login(data) {
  return await httpRequest(`${BaseURL}/session/login`, "POST", data);
}

export async function getTrackData() {
  return await httpRequest(`${BaseURL}/entry/track`);
}

export async function getUsersData() {
  return await httpRequest(`${BaseURL}/memberShip/members`);
}

export async function getUsersMailData() {
  return await httpRequest(`${BaseURL}/memberShip/membersMail`);
}

export async function getMailData() {
  return await httpRequest(`${BaseURL}/emails`);
}

export async function sendMail(data) {
  return await httpRequest(`${BaseURL}/emails/send`, "POST", data);
}
