import React, { useEffect, useState } from "react";
import { Button, Input, Label, SearchField } from "react-aria-components";
import { useFilter } from "react-aria";
import UsersTable from "./UsersTable";
import { getUsersData, getUsersMailData } from "../../../services/services";
import { TfiReload } from "react-icons/tfi";
import { clsx } from "clsx";

const Users = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeOptions = [25, 50, 75];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [selectedSeason, setSelectedSeason] = useState(""); // State for selected season
  const [isOpen, setIsOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const seasons = [
    "2024-2025",
    "2025-2026",
    "2026-2027",
    "2027-2028",
    "2028-2029",
    "2029-2030",
  ];

  const fetchData = async () => {
    try {
      const { data, response } = await getUsersData();

      if (response.status === 200) {
        setData(data.data);
      }
    } catch (error) {
      if (error.response) return;
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  let { contains } = useFilter({
    sensitivity: "base",
  });

  const updateUserList = (updatedUser) => {
    setData((prevList) =>
      prevList.map((user) =>
        user._id === updatedUser._id ? { ...user, ...updatedUser } : user,
      ),
    );
  };

  let matchedComposers = data.filter((composer) => {
    const matchesSearch =
      value.length > 2
        ? contains(composer.USERNAME.toLowerCase().trim(), value.toLowerCase())
        : true;

    const matchesSeason = selectedSeason
      ? composer.SEASON === selectedSeason
      : true;

    return matchesSearch && matchesSeason;
  });

  const totalItems = matchedComposers.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    if (page === 0) return setCurrentPage(1);
    if (page > totalPages) return setCurrentPage(totalPages);
    setCurrentPage(page);
  };

  const onSeasonChange = (item) => {
    setSelectedSeason(item);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleReload = async () => {
    setAnimate(true);
    await fetchData();
    setTimeout(() => setAnimate(false), 1500);
  };

  return (
    <section>
      <section className="bg-gray-100 w-full p-2 overflow-hidden">
        <div className="flex gap-3 items-center justify-between w-full">
          <SearchField className="flex items-center gap-5 p-2">
            <section>
              <Label className="block text-sm font-medium text-gray-700 mb-1 font-semibold">
                Rechercher un adhérent
              </Label>
              <Input
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setCurrentPage(1);
                }}
                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Rechercher ... "
              />
              <Button
                onPress={() => setValue("")}
                className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
                aria-label="Clear search"
              >
                ✕
              </Button>
            </section>

            <section className="z-[1] relative inline-block">
              <Label className="block text-sm font-medium text-gray-700 mb-1 font-semibold">
                Choisir une saison
              </Label>
              <div className="flex items-center">
                <div
                  className="flex justify-between gap-2 items-center p-2 pl-6 pr-6 bg-white border border-gray-300 text-black rounded-md cursor-pointer hover:bg-gray-100"
                  onClick={toggleDropdown}
                >
                  <span>{selectedSeason || "Toutes saisons"}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={`h-5 w-5 transform transition-transform duration-200 ${isOpen ? "rotate-180" : ""}`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
                <Button
                  onPress={() => setSelectedSeason("")}
                  className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
                  aria-label="Clear search"
                >
                  ✕
                </Button>
              </div>

              {isOpen && (
                <div className="absolute left-0 mt-2 w-full bg-white border-2 border-gray-300 rounded-md shadow-lg">
                  <ul>
                    {seasons.map((season, index) => (
                      <li
                        key={index}
                        className="px-6 py-3 hover:bg-gray-100 cursor-pointer"
                        onClick={() => onSeasonChange(season)}
                      >
                        {season}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </section>

            <section>
              <Label className="block text-sm font-medium text-gray-700 mb-1 font-semibold">
                Mise a jours
              </Label>
              <button
                className="p-3 rounded border-2 hover:border-r-gray-500 transition-border duration-500"
                onClick={handleReload}
              >
                <TfiReload className={clsx(animate && "animate-spin")} />
              </button>
            </section>
          </SearchField>

          <div className="flex items-center gap-3 text-white">
            <Label className="font-semibold text-black text-right pl-2">
              Résultat:{" "}
            </Label>
            <p className="center text-black px-2 rounded-md border-[#8f8f8f] border p-2">
              {matchedComposers.length} /{" "}
              {
                data.filter(
                  (composer) =>
                    !selectedSeason || composer.SEASON === selectedSeason,
                ).length
              }{" "}
              adhérent
            </p>
          </div>
        </div>
        {/**/}
        <section className=" shadowCustom w-full h-[68vh] mt-2 rounded max-h-[70vh] !overflow-scroll">
          <UsersTable
            data={matchedComposers}
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
            updateUserList={updateUserList}
          />
        </section>
        <div className="flex justify-between mt-2">
          <div>
            <Label className="text-black">Éléments par page: </Label>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="text-black px-2 rounded-md border-[#8f8f8f] border"
            >
              {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {data.length > pageSize && (
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 text-back">
                <p className="">{`Page `}</p>
                <Input
                  className="bg-transparent w-8 text-center border border-black rounded outline-none"
                  value={currentPage}
                  onChange={(e) => {
                    handlePageChange(Number(e.target.value));
                  }}
                />
                <p className="">{` / ${totalPages}`}</p>
              </div>

              <Button
                onPress={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-black px-2 rounded-md border-[#8f8f8f] border"
              >
                Précédent
              </Button>
              <Button
                onPress={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-black px-2 rounded-md border-[#8f8f8f] border"
              >
                Suivant
              </Button>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};

export default Users;
// payer ça cotisation , si actif  , commentaire , role ,   saison(années)
