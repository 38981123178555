import React, { useState, useEffect, useCallback, memo } from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaGamepad,
  FaBook,
  FaMask,
  FaCalendar,
  FaMoneyBillWave,
  FaUserTag,
} from "react-icons/fa";
import { MdClose, MdLocationOn, MdInfo } from "react-icons/md";
import { formatPhoneNumber, dateToISO } from "../../../utils/utils";

const InputField = memo(
  ({ label, name, value, displayValue, onChange, icon, type = "text" }) => (
    <div className="mb-4">
      <label
        className="block text-sm font-medium text-gray-700 mb-1"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="relative">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
          {icon}
        </span>
        <input
          type={type}
          id={name}
          name={name}
          value={displayValue || value}
          onChange={onChange}
          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  ),
);

const TextareaField = memo(({ label, name, value, onChange, icon }) => (
  <div className="mb-4">
    <label
      className="block text-sm font-medium text-gray-700 mb-1"
      htmlFor={name}
    >
      {label}
    </label>
    <div className="relative">
      <span className="absolute top-2 left-2 text-gray-400">{icon}</span>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        rows="3"
      />
    </div>
  </div>
));

const SwitchField = memo(({ label, name, checked, onChange }) => (
  <label htmlFor={name} className="flex items-center cursor-pointer">
    <div className="relative">
      <input
        type="checkbox"
        id={name}
        name={name}
        className="sr-only"
        checked={checked}
        onChange={onChange}
      />
      <div
        className={`block w-14 h-8 rounded-full transition-colors duration-300 ease-in-out ${checked ? "bg-blue-500" : "bg-gray-300"}`}
      ></div>
      <div
        className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${checked ? "transform translate-x-6" : ""}`}
      ></div>
    </div>
    <div className="ml-3 text-sm font-medium text-gray-700">{label}</div>
  </label>
));

const TabButton = memo(({ value, icon, label, activeTab, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center px-4 py-2 rounded-t-lg ${
      activeTab === value
        ? "bg-blue-500 text-white"
        : "bg-gray-200 text-gray-700"
    }`}
  >
    {icon}
    <span className="ml-2">{label}</span>
  </button>
));

const UserProfileModal = ({ isOpen, onClose, userData, onSave }) => {
  const [editedData, setEditedData] = useState(null);
  const [activeTab, setActiveTab] = useState("personal");

  useEffect(() => {
    if (userData) {
      setEditedData(userData);
    }
  }, [userData]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;

    if (name === "PHONE") {
      // Ne garde que les chiffres pour le stockage
      const cleanedValue = value.replace(/\D/g, "");
      setEditedData((prev) => ({
        ...prev,
        [name]: cleanedValue,
      }));
    } else {
      setEditedData((prev) => ({
        ...prev,
        [name]: name === "PRACTICE_DOMAIN" ? value.split(", ") : value,
      }));
    }
  }, []);

  const handleSwitchChange = useCallback((name) => {
    setEditedData((prev) => {
      let newValue = !prev[name];
      if (name === "PAID") newValue = newValue ? "true" : "false";
      return { ...prev, [name]: newValue };
    });
  }, []);

  const handleSave = useCallback(() => {
    onSave(editedData);
    onClose();
  }, [editedData, onSave, onClose]);

  const handleTabChange = useCallback((value) => {
    setActiveTab(value);
  }, []);

  if (!isOpen || !editedData) return null;

  const practiceDomains = [
    { value: "Cosplay", icon: <FaMask />, label: "Cosplay" },

    { value: "Culture Geek", icon: <FaGamepad />, label: "Culture Geek" },

    {
      value: "Culture de l'imaginaire",
      icon: <FaBook />,
      label: "Culture de l'imaginaire",
    },
  ];

  return (
    <section className="z-50 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 ">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
        <div className="sticky top-0 z-10 bg-white border-b px-6 py-4 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold text-gray-800">
              Profil Utilisateur
            </h2>
            <p className="block text-[11px] font-medium text-gray-700 mb-1">
              ID: {editedData["_id"]}
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-red-500 hover:text-red-700 transition-color duration-500"
          >
            <MdClose size={24} />
          </button>
        </div>
        <section
          className="overflow-y-auto p-6 "
          style={{ maxHeight: "calc(90vh - 140px)" }}
        >
          <div className="flex space-x-2 overflow-x-auto   ">
            <TabButton
              value="personal"
              icon={<FaUser />}
              label="Informations Personnelles"
              activeTab={activeTab}
              onClick={() => handleTabChange("personal")}
            />
            <TabButton
              value="contact"
              icon={<FaEnvelope />}
              label="Contact"
              activeTab={activeTab}
              onClick={() => handleTabChange("contact")}
            />
            <TabButton
              value="interests"
              icon={<FaGamepad />}
              label="Intérêts"
              activeTab={activeTab}
              onClick={() => handleTabChange("interests")}
            />
            <TabButton
              value="membership"
              icon={<FaUserTag />}
              label="Adhésion"
              activeTab={activeTab}
              onClick={() => handleTabChange("membership")}
            />
          </div>

          {activeTab === "personal" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border border-gray-300 p-2 rounded-tr-lg rounded-br-lg rounded-bl-lg">
              <InputField
                label="Nom"
                name="NAME"
                value={editedData["NAME"] || ""}
                onChange={handleInputChange}
                icon={<FaUser />}
              />
              <InputField
                label="Prénom"
                name="FIRST_NAME"
                value={editedData["FIRST_NAME"] || ""}
                onChange={handleInputChange}
                icon={<FaUser />}
              />
              <InputField
                label="Nom d'utilisateur"
                name="USERNAME"
                value={editedData["USERNAME"] || ""}
                onChange={handleInputChange}
                icon={<FaUser />}
              />
              <InputField
                label="Date de naissance"
                name="BIRTH_DATE"
                value={dateToISO(editedData.BIRTH_DATE)}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "BIRTH_DATE",
                      value: new Date(e.target.value).getTime(),
                    },
                  })
                }
                type="date"
                icon={<FaCalendar />}
              />
              <SwitchField
                label="PMR"
                name="DISABLED_ACCESS"
                checked={editedData.DISABLED_ACCESS || false}
                onChange={() => handleSwitchChange("DISABLED_ACCESS")}
              />
              <SwitchField
                label="Véhicule"
                name="VEHICLE"
                checked={editedData.VEHICLE || false}
                onChange={() => handleSwitchChange("VEHICLE")}
              />
            </div>
          )}

          {activeTab === "contact" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border border-gray-300 p-2 rounded-tr-lg rounded-br-lg rounded-bl-lg">
              <InputField
                label="Adresse"
                name="ADDRESS"
                value={editedData["ADDRESS"] || ""}
                onChange={handleInputChange}
                icon={<MdLocationOn />}
              />
              <InputField
                label="Code postal"
                name="ZIP_CODE"
                value={editedData["ZIP_CODE"] || ""}
                onChange={handleInputChange}
                icon={<MdLocationOn />}
              />
              <InputField
                label="Ville"
                name="CITY"
                value={editedData["CITY"] || ""}
                onChange={handleInputChange}
                icon={<MdLocationOn />}
              />
              <InputField
                label="Email"
                name="EMAIL"
                value={editedData["EMAIL"] || ""}
                onChange={handleInputChange}
                icon={<FaEnvelope />}
              />
              <InputField
                label="Téléphone"
                name="PHONE"
                value={editedData["PHONE"] || ""}
                displayValue={formatPhoneNumber(editedData["PHONE"])}
                onChange={handleInputChange}
                icon={<FaPhone />}
              />
              <InputField
                label="Pseudo Discord"
                name="DISCORD_ID"
                value={editedData["DISCORD_ID"] || ""}
                onChange={handleInputChange}
                icon={<FaGamepad />}
              />
            </div>
          )}

          {activeTab === "interests" && (
            <section className="grid grid-cols-1 gap-6 border border-gray-300 p-2 rounded-tr-lg rounded-br-lg rounded-bl-lg">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Domaine de pratique
                <hr />
              </label>
              <div className="flex items-center justify-between w-full">
                {practiceDomains.map((domain) => (
                  <SwitchField
                    key={domain.value}
                    label={domain.label}
                    name={domain.value}
                    checked={
                      editedData.PRACTICE_DOMAIN?.includes(domain.value) ||
                      false
                    }
                    onChange={() => {
                      setEditedData((prev) => {
                        const currentDomains = prev.PRACTICE_DOMAIN || [];
                        const updatedDomains = currentDomains.includes(
                          domain.value,
                        )
                          ? currentDomains.filter(
                              (item) => item !== domain.value,
                            )
                          : [...currentDomains, domain.value];
                        return { ...prev, PRACTICE_DOMAIN: updatedDomains };
                      });
                    }}
                  />
                ))}
              </div>
              <TextareaField
                label="Motivation"
                name="MOTIVATION"
                value={editedData["MOTIVATION"] || ""}
                onChange={handleInputChange}
                icon={<FaBook />}
              />
              <InputField
                label="Cosplay"
                name="COSPLAY"
                value={editedData["COSPLAY"] || ""}
                onChange={handleInputChange}
                icon={<FaMask />}
              />
              <TextareaField
                label="Culture Geek"
                name="GEEK_CULTURE"
                value={editedData["GEEK_CULTURE"] || ""}
                onChange={handleInputChange}
                icon={<FaGamepad />}
              />
              <TextareaField
                label="Culture de l'imaginaire"
                name="IMAGINARY_CULTURE"
                value={editedData["IMAGINARY_CULTURE"] || ""}
                onChange={handleInputChange}
                icon={<FaBook />}
              />
              <TextareaField
                label="Allergies"
                name="ALLERGIES"
                value={editedData["ALLERGIES"] || ""}
                onChange={handleInputChange}
                icon={<MdInfo />}
              />
              <TextareaField
                label="Notes"
                name="NOTES"
                value={editedData["NOTES"] || ""}
                onChange={handleInputChange}
                icon={<MdInfo />}
              />
            </section>
          )}

          {activeTab === "membership" && (
            <section className="grid grid-cols-1 md:grid-cols-2 gap-6 border border-gray-300 p-2 rounded-tr-lg rounded-br-lg rounded-bl-lg">
              <InputField
                label="Statut"
                name="STATUS"
                value={editedData["STATUS"] || ""}
                onChange={handleInputChange}
                icon={<FaUserTag />}
              />
              <InputField
                label="Méthode de paiement"
                name="PAYMENT_METHOD"
                value={editedData["PAYMENT_METHOD"] || ""}
                onChange={handleInputChange}
                icon={<FaMoneyBillWave />}
              />
              <SwitchField
                label="Règlement lu"
                name="RULES_READ"
                checked={editedData.RULES_READ || false}
                onChange={() => handleSwitchChange("RULES_READ")}
              />
              <SwitchField
                label="Actif"
                name="ACTIF"
                checked={editedData.ACTIF || false}
                onChange={() => handleSwitchChange("ACTIF")}
              />
              <InputField
                label="Date de signature"
                name="SIGNATURE_DATE"
                value={dateToISO(editedData.SIGNATURE_DATE)}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "SIGNATURE_DATE",
                      value: new Date(e.target.value).getTime(),
                    },
                  })
                }
                type="date"
                icon={<FaCalendar />}
              />
              <InputField
                label="Rôle"
                name="ROLE"
                value={editedData["ROLE"] || ""}
                onChange={handleInputChange}
                icon={<FaUserTag />}
              />
              <InputField
                label="Saison"
                name="SEASON"
                value={editedData["SEASON"] || ""}
                onChange={handleInputChange}
                icon={<FaCalendar />}
              />
              <SwitchField
                label="Invité"
                name="IS_INVITED"
                checked={editedData.IS_INVITED || false}
                onChange={() => handleSwitchChange("IS_INVITED")}
              />
            </section>
          )}
        </section>
        <section className="sticky bottom-0 bg-white z-10 flex justify-end space-x-4 p-6 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-300"
          >
            Annuler
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
          >
            Enregistrer
          </button>
        </section>
      </div>
    </section>
  );
};

export default UserProfileModal;
