import { clsx } from "clsx";
import { FaChevronDown } from "react-icons/fa";
import React from "react";

const CustomDropBox = ({ value, onChange, options, label, view = true }) => {
  const getOptionColor = (bgColor) => {
    const colorMap = {
      "bg-green-500": "text-green-600 bg-green-50",
      "bg-red-500": "text-red-600 bg-red-50",
      "bg-yellow-500": "text-yellow-600 bg-yellow-50",
    };
    return colorMap[bgColor] || "text-gray-600 bg-gray-50";
  };

  const renderOption = (option) => (
    <option
      key={option.value}
      value={option.value}
      className={`${getOptionColor(option.bgColor)} py - 1.5`}
    >
      {option.icon} {option.label}
    </option>
  );

  const selectedOption = options.find(
    (opt) => String(opt.value) === String(value),
  );

  return (
    <div
      className={clsx("relative inline-block w-full group py-1.5 rounded-full")}
    >
      {view ? (
        <div className="relative">
          <label className="sr-only">{label}</label>
          <select
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            className={clsx(
              getOptionColor(selectedOption.bgColor),
              "w-full bg-white border-2 border-gray-200 rounded-full hover:border-blue-400 cursor-pointer text-sm px-4 py-2 appearance-none transition-all duration-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent",
            )}
          >
            {options.map(renderOption)}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <FaChevronDown className="w-4 h-4 text-gray-400 transform group-hover:text-blue-500 transition-colors duration-200" />
          </div>
        </div>
      ) : selectedOption ? (
        <div
          className={clsx(
            `${getOptionColor(selectedOption.bgColor)} py-1.5 px-4 rounded-full flex items-center`,
          )}
        >
          <span className="mr-2">{selectedOption.icon}</span>{" "}
          {selectedOption.label}
        </div>
      ) : (
        <div className="text-gray-600 bg-gray-50 py-1.5 px-4 rounded-full">
          No selection
        </div>
      )}
    </div>
  );
};
export default CustomDropBox;
