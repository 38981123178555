import React from "react";

const extractEmailContent = (htmlContent) => {
  try {
    // Créer un élément temporaire pour parser le HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Sélectionner toutes les divs avec la classe content
    const contentDivs = doc.querySelectorAll(".content");

    // Prendre la deuxième div content (celle qui contient le contenu réel)
    if (contentDivs.length >= 2) {
      const innerContentDiv = contentDivs[1];
      return innerContentDiv.innerHTML.trim();
    }
    return null;
  } catch (error) {
    console.error("Error extracting content:", error);
    return null;
  }
};

const EmailViewModal = ({ email, onClose }) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const emailContent = extractEmailContent(email.content);

  return (
    <div className="fixed inset-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl transform transition-all animate-in fade-in duration-200">
        <div className="flex justify-between items-center p-6 border-b">
          <div className="flex-1">
            <h2 className="text-2xl font-semibold text-gray-900 mb-1">
              {email.subject}
            </h2>
            <p className="text-sm text-gray-500">
              {email.to.length} destinataire{email.to.length > 1 ? "s" : ""}
            </p>
          </div>
          <button
            onClick={onClose}
            className="rounded-full p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-colors"
            aria-label="Fermer"
          >
            X
          </button>
        </div>

        <div className="p-6 space-y-4">
          <div className="space-y-3">
            <div className="flex items-center gap-3 text-sm">
              <div className="bg-blue-100 rounded-full p-2">📧</div>
              <div className="flex-1">
                <p className="text-gray-500">De</p>
                <p className="font-medium text-gray-900">{email.from}</p>
              </div>
            </div>

            <div className="flex items-center gap-3 text-sm">
              <div className="bg-purple-100 rounded-full p-2">👥</div>
              <div className="flex-1">
                <p className="text-gray-500">À</p>
                <p className="font-medium text-gray-900">
                  {email.to.join(", ")}
                </p>
              </div>
            </div>

            <div className="flex items-center gap-3 text-sm">
              <div className="bg-green-100 rounded-full p-2">🕒</div>
              <div className="flex-1">
                <p className="text-gray-500">Date</p>
                <p className="font-medium text-gray-900">
                  {formatDate(email.date)}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 border-t pt-6">
            <div
              className="prose prose-sm max-w-none"
              dangerouslySetInnerHTML={{ __html: emailContent || "" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailViewModal;
