import React, { useState } from "react";
import {
  Cell,
  Collection,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
  useTableOptions,
} from "react-aria-components";
import { customCompare, formatPhoneNumber } from "../../../utils/utils";
import Modal from "./UsersModal";
import CustomDropBox from "./UsersCustomDropBox";
import { clsx } from "clsx";
import { FaUsers } from "react-icons/fa";
const activityStatuses = [
  { value: true, label: "Actif", icon: "✅", bgColor: "bg-green-500" },
  { value: false, label: "Inactif", icon: "❌", bgColor: "bg-red-500" },
];

const statuses = [
  { value: "true", label: "Payée", icon: "💸", bgColor: "bg-green-500" },
  { value: "false", label: "Non payée", icon: "💳", bgColor: "bg-red-500" },
  {
    value: "pending",
    label: "En attente",
    icon: "⏳",
    bgColor: "bg-yellow-500",
  },
];

const regulations = [
  { value: true, label: "Accepté", icon: "✅", bgColor: "bg-green-500" },
  { value: false, label: "Non lue", icon: "❌", bgColor: "bg-red-500" },
];

const onUpdate = async (user, field, newValue, updateUserList) => {
  const updatedUser = { ...user };
  if (field) {
    updatedUser[field] = newValue;
  }

  try {
    updateUserList(field ? updatedUser : user);
  } catch (error) {
    console.error("Error updating user:", error);
  }
};

function checkColID(col, item, updateUserList) {
  switch (col) {
    case "ACTIF":
      return (
        <CustomDropBox
          value={item[col]}
          onChange={(newValue) => onUpdate(item, col, newValue, updateUserList)}
          options={activityStatuses}
          label="Statut d'activité"
          view={false}
        />
      );

    case "PAID":
      return (
        <CustomDropBox
          value={item[col]}
          onChange={(newValue) => onUpdate(item, col, newValue, updateUserList)}
          options={statuses}
          label="Statut de la cotisation"
        />
      );

    case "RULES_READ":
      return (
        <CustomDropBox
          value={item[col]}
          onChange={(newValue) => onUpdate(item, col, newValue, updateUserList)}
          options={regulations}
          label="Règlement"
        />
      );

    case "PHONE":
      return <p>{formatPhoneNumber(item[col])}</p>;

    case "CITY":
      return (
        <p>
          {item[col]} - {item["ZIP_CODE"]}
        </p>
      );

    default:
      return item[col];
  }
}

const MyTableHeader = ({ columns }) => {
  let { allowsDragging } = useTableOptions();

  return (
    <TableHeader className="bg-[#3f3f46] border-[#3f3f46] border-x-2 rounded-md sticky top-0">
      {allowsDragging && <Column />}
      <Collection items={columns}>
        {(column) => (
          <Column
            className="p-1 text-white"
            key={column.id}
            isRowHeader={column.isRowHeader}
          >
            {column.name}
          </Column>
        )}
      </Collection>
      <Column />
    </TableHeader>
  );
};
//
//
const MyRow = ({ id, columns, item, onShowDetails, updateUserList }) => {
  console.log("123", item.IS_INVITED);
  return (
    <Row
      id={id}
      item={item}
      className={clsx(
        item["IS_INVITED"] ? "bg-slate-500 text-white" : "bg-white",
        "border-[#3f3f46] border-2 text-black",
      )}
    >
      {columns.map((col, index) => {
        let cellValue = checkColID(col.id, item, updateUserList);

        return (
          <Cell key={index} className={clsx("h-[40px] p-2 text-center")}>
            {cellValue}
          </Cell>
        );
      })}

      <Cell className="h-[40px] p-2 text-center">
        <div className="flex items-center gap-5">
          <button
            onClick={() => onShowDetails(item)}
            className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
          >
            Détails
          </button>
        </div>
      </Cell>
    </Row>
  );
};

const UsersTable = ({ data, updateUserList }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const columns = [
    { name: "ACTIF", id: "ACTIF" },
    { name: "PSEUDO", id: "USERNAME", isRowHeader: true },
    { name: "NOM", id: "NAME" },
    { name: "PRENOM", id: "FIRST_NAME" },
    { name: "EMAIL", id: "EMAIL" },
    { name: "TELEPHONE", id: "PHONE" },
    { name: "REGLEMENT LU", id: "RULES_READ" },
    { name: "COTISATION", id: "PAID" },
    { name: "VILLE", id: "CITY" },
    { name: "SAISON", id: "SEASON" },
  ];

  let rows = [...data].sort(customCompare);

  const showDetails = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalOpen(false);
  };

  return (
    <>
      <Table
        aria-label="Users Table"
        className="w-full max-w-[100%] max-h-[70vh] rounded-md overflow-hidden text-nowrap"
      >
        <MyTableHeader columns={columns} />
        <TableBody
          className="text-center text-black max-h-[60vh] overflow-y-auto"
          renderEmptyState={() => (
            <div className="p-4 text-center text-gray-400">
              Aucun résultat trouvé.
            </div>
          )}
        >
          {rows.map((item, index) => (
            <MyRow
              key={index}
              id={index}
              columns={columns}
              item={item}
              onShowDetails={showDetails}
              updateUserList={updateUserList}
            />
          ))}
        </TableBody>
      </Table>
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        userData={selectedUser}
        onSave={(editedData) =>
          onUpdate(editedData, null, null, updateUserList)
        }
      />
    </>
  );
};

export default UsersTable;
